.rightTextSection {
  /* @apply absolute top-1/3 right-8 sm:right-12 md:right-20 lg:right-40 flex flex-col items-end; */
  @apply absolute bg-white p-4 opacity-90 sm:bg-transparent sm:p-0 sm:opacity-100 bottom-10 right-1/2 transform translate-x-1/2 sm:transform-none sm:top-1/3 sm:right-12 md:right-20 lg:right-40 flex flex-col items-center sm:items-end;
}

.leftTextSection {
  @apply absolute bg-white p-4 opacity-90 sm:bg-transparent sm:p-0 sm:opacity-100 bottom-10 right-1/2 transform translate-x-1/2 sm:transform-none sm:top-1/3 sm:left-12 md:left-20 lg:left-40 flex flex-col items-center sm:items-start;
}

.subtitle {
  @apply bg-gray500 text-gray100 inline-block text-base sm:text-xs p-1 rounded-md;
}

.title {
  @apply text-4xl sm:text-5xl md:text-6xl lg:text-7xl my-4;
}

.arrows {
  @apply w-8 h-8 absolute cursor-pointer flex justify-center items-center bg-gray300 rounded-full opacity-60 hover:opacity-100;
}
